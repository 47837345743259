import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Configuration } from './shared/angular-service';
import { HttpClient } from '@angular/common/http';

@NgModule({ imports: [], declarations: [], exports: [], providers: [] })
export class RestClientModule {
  public static forRoot(factory: () => Configuration): ModuleWithProviders<RestClientModule> {
    return {
      ngModule: RestClientModule,
      providers: [{ provide: Configuration, useFactory: factory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: RestClientModule, @Optional() http: HttpClient) {
    if(parentModule) {
      throw new Error('RestClientModule is already loaded.Import in your base AppModule only.');
    }
    if(!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule!');
    }
  }
}

export const RestClientConfig = Configuration;